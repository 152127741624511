@import "../../variables";

.sidebar-container {
  flex-shrink: 0;

  .sidebar {
    display: flex;
    flex-direction: column;
    width: 256px;
    height: 100%;
    flex-grow: 1;
    background-color: white;
    border: 1px $gray-200 solid;

    .sidebar-inner {
      padding: 25px 0;
      display: flex;
      flex-direction: column;

      .logo-wrapper {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        padding: 0 16px;

        .brand-image {
          height: 48px;
          margin-bottom: 20px;
          padding: 0px 20px; } }

      .profile-button-wrapper {
        padding: 0 16px 10px 16px;
        border-bottom: 1px $gray-200 solid; }

      .navigation-wrapper {
        nav {
          padding: 20px 16px;

          div {
            margin-top: 12px; } }

        .idkyet {
          font-size: 11px;
          text-transform: uppercase;
          letter-spacing: 0.5px;
          font-weight: 500;
          color: #a9b9d1;
          user-select: none; }

        .navigation-item {
          display: flex;
          text-decoration: none;
          width: auto;
          padding: 8px 10px;
          border-radius: 8px;
          margin-top: 4px;
          font-size: 14px;
          font-weight: 500;
          color: $gray-500;

          &.current {
            background-color: $blue-50;
            color: #4d8ac3; }

          &:not(.navigation-item.current):hover {
            background-color: white; }

          svg {
            width: 20px;
            margin-right: 10px; } } } } } }
